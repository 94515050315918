import { apiRoot } from "../../api/api";

export const fetchBanners = async () => {
  try {
    const res = await apiRoot.get("/banners/");
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
