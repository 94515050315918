import React, { useEffect } from "react";

import Banner from "../banner/Banner";
import Brand from "../brand";
import Product from "../product/Product";
import Partner from "../partner";
import Schema from "../schema-production";
import Order from "../order";
import InfoUs from "../infoUs";
import Commitments from "../commitments/Commitments";
import Reviews from "../Reviews/Review";
import CheckModal from "../CheckModal/CheckModal";

import "./Home.css";
import StockInfo from "../stockInfo/StockInfo";

function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="home">
      <Banner />
      <InfoUs />
      <Partner />
      <StockInfo />
      <Order />
      <Schema /> 
      <Product />
      <Commitments />
      <Reviews />
      <Brand />
      <CheckModal />
    </div>
  );
}

export default Home;
