import React from "react";
import YouTubeVideo from "../youTube/YouTube";

import "./review.css";

const Reviews = () => {
  return (
    <section className="reviews">
      <div className="container">
        <div className="video-content">
          <div className="youtube-video-wrapper">
            <YouTubeVideo videoId="TPANisGDA78?si=82RGnT9A5I06_34Q" />
          </div>
          <div className="youtube-video-wrapper">
            <YouTubeVideo videoId="psLJ5NSZVbk?si=hSAqlT57YowNxQ6U" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;

// https://youtu.be/
