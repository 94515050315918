import { createSlice } from "@reduxjs/toolkit";
import { callRequetsAsync } from "./action";

const initialState = {
  loading: "idle",
  data: null,
  error: null,
};

const callSlice = createSlice({
  name: "call-requests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(callRequetsAsync.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(callRequetsAsync.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(callRequetsAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      });
  },
});

export default callSlice.reducer;
