import React from "react";
import CatalogCom from "../components/CatalogCom/CatalogCom";

function CatalogPage() {
  return (
    <div>
      <CatalogCom />
    </div>
  );
}

export default CatalogPage;
