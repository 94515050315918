import React from "react";
import WhyCom from "../components/Why";

function WhyPage() {
  return (
    <div>
      <WhyCom />
    </div>
  );
}

export default WhyPage;
