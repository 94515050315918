import React, { useEffect, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import "./CheckModal.css";

const CheckModal = ({ closeModal, isActive }) => {
  const modalRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (isActive) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isActive]);

  return (
    <div className={`check-modal ${isActive ? "active" : ""}`}>
      <div className="modal-container" ref={modalRef}>
        <h2>
          Спасибо за ваше обращение! Наши менеджеры свяжутся с вами в ближайшее
          время!
        </h2>
        <div className="position" onClick={closeModal}>
          <IoMdClose />
        </div>
      </div>
    </div>
  );
};

export default CheckModal;
