import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsApi } from "../../redux/products/action";
import Card from "./Card";

import "./Product.css";
import Loading from "../../ui/loading";

function Product() {
  const dispatch = useDispatch();
  const { products, status, error } = useSelector((state) => state.products);
  useEffect(() => {
    dispatch(fetchProductsApi());
  }, [dispatch]);

  if (status === "loading") {
    return <Loading />;
  }

  return (
    <section className="product">
      <div className="container">
        <div className="product-title">Какие изделия шьём</div>
        <div className="product-text">
          Специализируемся на женской одежде 1-2-3 слоя. Шьём всё, кроме вязаных
          изделий и нежного белья
        </div>
        <div className="product-box">
          <div className="product-box-card">
            {status === "failed" && <div>Error {error}</div>}
            {status === "succeeded" && products.length === 0 && (
              <div>No products available</div>
            )}
            {status === "succeeded" &&
              products.length > 0 &&
              products
                .slice(0, 10)
                .map((item) => <Card key={item.id} products={item} />)}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Product;
