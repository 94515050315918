import { configureStore } from "@reduxjs/toolkit";
import BannersSlice from "./banners/slice";
import callSlice from "./call-requests/slice";
import ProductsSlice from "./products/slice";
import productIdSlice from "./productId/slice";

const store = configureStore({
  reducer: {
    banners: BannersSlice,
    callRequests: callSlice,
    productId: productIdSlice,
    products: ProductsSlice,
  },
});

export default store;
