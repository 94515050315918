import { createAsyncThunk } from "@reduxjs/toolkit";
import { callRequets } from "./api";

export const callRequetsAsync = createAsyncThunk(
  "requests",
  async ({ fullname, phone }) => {
    const resPostAsync = await callRequets({ fullname, phone });
    return resPostAsync;
  }
);
