import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsApi } from "../../redux/products/action";

import Card from "../product/Card";
import "./Catalog.css";
import Loading from "../../ui/loading";

function CatalogCom() {
  const dispatch = useDispatch();
  const { products, status, error } = useSelector((state) => state.products);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    dispatch(fetchProductsApi());
  }, [dispatch]);

  if (status === "loading") {
    return <Loading />;
  }

  return (
    <>
      <div className="catalog-header"></div>
      <div className="catalog-container">
        <div className="container">
          <div className="catalog-text">Наша коллекция</div>
          <div className="collection">
            <div className="catalog-content">
              {status === "failed" && <div>Error {error}</div>}
              {status === "succeeded" && products.length === 0 && (
                <div>No products available</div>
              )}
              {status === "succeeded" &&
                products.length > 0 &&
                products.map((item) => <Card key={item.id} products={item} isCatalog={true}/>)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CatalogCom;
