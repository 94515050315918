import React from "react";
import Reviews from "../components/Reviews/Review";

const RewiesPage = () => {
  return (
    <div>
      <Reviews />
    </div>
  );
};

export default RewiesPage;
