import React, { useEffect } from "react";
import Partner from "../partner";

import "./Why.css";

function WhyCom() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="why-container">
      <div className="container">
        <h2>Преимущества нашей компании!</h2>
        <div className="why-content">
          <p>
            Открою перед вами преимущества нашей компании — швейной фабрики
            «IMPERIAL STYLE». Мы готовы к сотрудничеству и приглашаем заказчиков
            в мир высококачественного шитья.
          </p>

          <p>
            🧵 Точная стоимость будет уточнена после создания образца. Стоимость
            изготовления одного образца составляет 10000 рублей и выше, включая
            работу над образцом по фото/видео и печать лекала. После окончания с
            нашей стороны будет организованы фотосессия.
          </p>

          <p>
            Минимальный объем заказа начинается от 1000 штук одной расцветки
            модели.
          </p>

          <p>
            Предоплата составляет 50%, остальные 50% оплачиваются после
            получения накладных от доставки.
          </p>

          <p>
            Крупным заказчикам предоставляется бесплатная наземная доставка
            через карго.
          </p>

          <p>
            Время доставки варьируется от 3 до 10 дней, в зависимости от
            выбранного вами способа доставки — авиа или наземный транспорт.
          </p>

          <p>
            Все детали заказа обсуждаются через видео/аудио звонок, чтобы
            убедиться, что ваша одежда будет идеально соответствовать вашим
            ожиданиям.
          </p>

          <p>
            Оплата осуществляется на расчетный счет или по предварительной
            договоренности.
          </p>

          <p>
            Наша производственная мощность позволяет нам шить от 15 000 и выше
            изделий в неделю, в зависимости от типа продукции.
          </p>

          <p>
            Свяжитесь с нашим специалистом отдела продаж, Жолдошбеком Б., чтобы
            обсудить детали и начать сотрудничество с нами.
          </p>

          <p>Юридическое наименование: ОсОО «IMPERIAL STYLE».</p>
        </div>
      </div>
      <Partner />
    </div>
  );
}

export default WhyCom;
