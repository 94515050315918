import React from "react";
import YouTube from "react-youtube";

const YouTubeVideo = ({ videoId }) => {
  const videoOnReady = (event) => {
    event.target.pauseVideo();
  };

  const opts = {
    width: "100%",
    height: "100%",
    playerVars: {
      autoplay: 1,
      muted: 1,
    },
  };

  return <YouTube videoId={videoId} opts={opts} onReady={videoOnReady} />;
};

export default YouTubeVideo;
