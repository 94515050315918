import React from "react";
import ProductId from "../components/productId/ProductId";

function ProductPage() {
  return (
    <div>
      <ProductId />
    </div>
  );
}

export default ProductPage;
