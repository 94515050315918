const commitments = [
  {
    id: 1,
    title: "Соблюдение сроков",
    description:
      "Мы понимаем, как важна своевременная поставка. В случае задержки заказа, мы обязуемся выплатить компенсацию в размере 30,000 сом за каждый день просрочки. Это наша гарантия ответственности перед вами.",
  },
  {
    id: 2,
    title: "Контроль качества",
    description:
      "Если процент брака превысит 3% от общего объёма продукции, мы полностью возместим стоимость бракованных изделий или переделаем их за наш счёт. Мы тщательно контролируем каждый этап производства.",
  },
  {
    id: 3,
    title: "Конфиденциальность",
    description:
      "Мы гарантируем полную конфиденциальность ваших разработок. Ваши идеи и модели будут надёжно защищены и не будут использованы без вашего согласия.",
  },
];

export default commitments;
