import React from "react";

import "./StockInfo.css";

const StockInfo = () => {
  return (
    <section className="stock-info">
      <div className="container stock-info__container">
        <h2>"Ищете выгодные условия по пошиву и надёжного производителя?"</h2>
        <h3>Оставьте свой номер, и мы рассчитаем стоимость вашей модели.</h3>
        <p className="stock-info__desc"> Вы получите:</p>
        <ul>
          <li>10% скидку на первый заказ</li>
          <li>Бесплатный образец вашей модели</li>
          <li>
            Работа за наш счёт -
            <span>вы платите только за фирнитуру и ткань</span>
          </li>
        </ul>
        <p>
          И это ещё не всё! У нас есть и другие интересные предложения для новых
          клиентов. Акция действует до конца октября!
        </p>
      </div>
    </section>
  );
};

export default StockInfo;
