import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBannersApi } from "../../redux/banners/action";
import Carousel from "react-bootstrap/Carousel";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Banner.css";

function Banner() {
  const dispatch = useDispatch();
  const { banners, status, error } = useSelector((state) => state.banners);

  const handleScroll = (e) => {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchBannersApi());
    }
  }, [dispatch, status]);

  return (
    <>
      <div className="banner-top"></div>
      <div className="banner-container">
        <Carousel>
          {status === "loading" && (
            <Carousel.Item interval={800}>
              <div className="loading-placeholder"></div>
            </Carousel.Item>
          )}
          {status === "failed" && (
            <Carousel.Item interval={800}>
              <div className="container">
                <div className="error-placeholder">Error {error}</div>
              </div>
            </Carousel.Item>
          )}
          {status === "succeeded" &&
            banners.map((img, index) => (
              <Carousel.Item
                interval={800}
                key={index}
                className="carousel-item"
              >
                <img
                  className="carousel-image"
                  src={img.photo}
                  alt="carousel"
                  loading="lazy"
                />
                <Carousel.Caption>
                  <a href="#review" className="link-btn" onClick={handleScroll}>
                    Оставить заявку
                  </a>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
        </Carousel>
      </div>
    </>
  );
}

export default Banner;
