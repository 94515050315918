import React from "react";
import { Link } from "react-router-dom";

function Card({ products, isCatalog }) {
  return (
    <div className="box">
      <Link
        to={isCatalog ? `/catalog/${products.id}` : `/${products.id}`}
        className="card-item"
      >
        <div className="image-container">
          <img src={products.main_photo} alt="card" loading="lazy" />
        </div>
        <div className="card-content">
          <h2>{products.name}</h2>
        </div>
      </Link>
    </div>
  );
}

export default Card;
