import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchProductsIdApi } from "../../redux/productId/action";

import "./content.css";
import Tabs from "../tabs";
import Loading from "../../ui/loading";

function ProductId() {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const { id } = useParams();
  const { product, status } = useSelector((state) => state.productId);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (id) {
      dispatch(fetchProductsIdApi(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (product && product.main_photo) {
      setSelectedPhoto(product.main_photo);
    }
  }, [product]);

  if (status === "loading") {
    return <Loading />;
  }

  if (!product) {
    return null;
  }

  return (
    <>
      <div className="product-header"></div>
      <div className="product-id-content">
        <div className="container">
          <div className="get-container">
            <div className="image-content">
              <div className="image-id">
                <img src={selectedPhoto} alt="product" loading="lazy" />
              </div>
              <div
                className={`image-content-photos ${
                  Array.isArray(product.photos) && product.photos.length > 5 ? "scroll-images" : ""
                }`}
              >
                {Array.isArray(product.photos) && product.photos.length > 0 ? (
                  product.photos.map((photo, index) => (
                    <img
                      className="image-content-photos-photo"
                      key={index}
                      src={photo.photo}
                      alt={`Product ${index + 1}`}
                      loading="lazy"
                      onClick={() => setSelectedPhoto(photo.photo)}
                    />
                  ))
                ) : (
                  <p style={{ textAlign: "center", marginTop: "10px" }}>
                    Допольнительных фотографий нет
                  </p>
                )}
              </div>
            </div>
            <div className="get-product-content">
              <h2>{product.name}</h2>
              <div className="content-block">
                <div className="block-description">
                  {product.vendor_code && (
                    <p>
                      <strong>Артикул:</strong>
                      {product.vendor_code}
                    </p>
                  )}
                  {product.fastener_type && (
                    <p>
                      <strong>Ткань:</strong>
                      {product.fastener_type}
                    </p>
                  )}
                  {product.fashion && (
                    <p>
                      <strong>Тип застёжки:</strong>
                      {product.fashion}
                    </p>
                  )}
                  {product.collar && (
                    <p>
                      <strong>Воротник:</strong>
                      {product.collar}
                    </p>
                  )}
                  {product.sleeve && (
                    <p>
                      <strong>Рукав:</strong>
                      {product.sleeve}
                    </p>
                  )}
                  {product.pocket && (
                    <p>
                      <strong>Карман:</strong>
                      {product.pocket}
                    </p>
                  )}
                  {product.lining && (
                    <p>
                      <strong>Подклад:</strong>
                      {product.lining}
                    </p>
                  )}
                  {product.lenght && (
                    <p>
                      <strong>Длина изделия:</strong>
                      {product.lenght}
                    </p>
                  )}
                  {product.min_quantity && (
                    <p>
                      <strong>Минимальное количество:</strong>
                      {product.min_quantity}
                    </p>
                  )}
                </div>
                <div className="block-size">
                  <h2>Размер:</h2>
                  <div className="box-size">
                    <div className="card-size">42</div>
                    <div className="card-size">44</div>
                    <div className="card-size">46</div>
                    <div className="card-size">48</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tabs-container">
            <Tabs />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductId;
