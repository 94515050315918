import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";

import "./Popup.css";
import CheckModal from "../CheckModal/CheckModal";
import { useDispatch, useSelector } from "react-redux";
import { callRequetsAsync } from "../../redux/call-requests/action";

function PopupCom({ setPopup }) {
  const [fullname, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.callRequests);

  useEffect(() => {
    if (data === "succeeded" && !error) {
      setModal(true);
    }
  }, [data, error]);

  const handleRequests = (e) => {
    e.preventDefault();
    if (fullname.trim() === "" || phone.trim() === "") {
      alert("Пожалуйста, заполните все поля.");
    } else {
      dispatch(callRequetsAsync({ fullname, phone })).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          setFullName("");
          setPhone("");
          setModal(true);
        }
      });
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <>
      <form className="popup-container" onSubmit={handleRequests}>
        <div className="popup-content">
          <h2>Связаться</h2>
          <p>
            Оставьте заявку и наши специалисты свяжутся с вами в ближайшее время
          </p>
          <input
            type="text"
            value={fullname}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Имя"
          />
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Номер телефона"
          />
        </div>
        <button type="submit">
          {loading === "loading" ? "Отправка..." : "Оформить заказ"}
        </button>
        <div className="popup-position">
          <IoMdClose
            onClick={() => setPopup(false)}
            style={{ fontSize: "20px" }}
          />
        </div>
      </form>
      {modal && <CheckModal closeModal={closeModal} isActive={modal} />}
    </>
  );
}

export default PopupCom;
