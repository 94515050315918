import React, { useEffect } from "react";

import "./Contact.css";
import ContactContent from "../contact-content";

function Contact() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="contact">
      <section className="contact-title">
        <div className="container">
          <div className="contact-text">Контактная информация</div>
          <ContactContent />
          <div className="contact-item">
            <div className="contact-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2925.298260940999!2d74.55938687661461!3d42.84543497115199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389ec9b0810e8fe7%3A0x2f2e91cc08e90cb9!2z0YPQuy4g0JrQvtC_0LXRgNC90LjQutCwLCDQkdC40YjQutC10Lo!5e0!3m2!1sru!2skg!4v1725254820918!5m2!1sru!2skg"
                width="600"
                height="450"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
