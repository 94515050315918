import React, { useEffect, useState } from "react";
import "./Order.css";
import { useDispatch, useSelector } from "react-redux";
import { callRequetsAsync } from "../../redux/call-requests/action";
import CheckModal from "../CheckModal/CheckModal";

function Order() {
  const [fullname, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.callRequests);

  useEffect(() => {
    if (data === "succeeded" && !error) {
      setModal(true);
    }
  }, [data, error]);

  const handleRequests = (e) => {
    e.preventDefault();
    if (fullname.trim() === "" || phone.trim() === "") {
      alert("Пожалуйста, заполните все поля.");
    } else {
      dispatch(callRequetsAsync({ fullname, phone })).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          setFullName("");
          setPhone("");
          setModal(true);
        }
      });
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <>
      <div className="order-container" id="review">
        <div className="container">
          <h2>Закажите образец</h2>
          <form className="form" onSubmit={handleRequests}>
            <input
              type="text"
              placeholder="Имя"
              className="name"
              value={fullname}
              onChange={(e) => setFullName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Номер телефона"
              className="phone-number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <button
              className="submit"
              type="submit"
              disabled={loading === "loading"}
            >
              {loading === "loading" ? "Отправка..." : "Заказать"}
            </button>
          </form>
          {error && (
            <p className="error-message" style={{ color: "#fff" }}>
              Произошла ошибка: {error}
            </p>
          )}
        </div>
      </div>
      {modal && <CheckModal closeModal={closeModal} isActive={modal} />}
    </>
  );
}

export default Order;
