import { createSlice } from "@reduxjs/toolkit";
import { fetchBannersApi } from "./action";

const initialState = {
  banners: [],
  status: "idle",
  error: null,
};

const BannerSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBannersApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBannersApi.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.banners = action.payload;
      })
      .addCase(fetchBannersApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("Ошибка при получении баннеров:", action.error);
      });
  },
});

export default BannerSlice.reducer;
