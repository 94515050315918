const tabs = [
  {
    id: 1,
    tabTitle: "Условия пошива",
    title: "Условия пошива заказа",
    content:
      "Минимальный заказ - 500 шт. изделий одной модели товара, в котором будут включены от 3 до 5 размеров и цветов. Подробнее о размерах и цветах вы сможете обсудить с личным менеджером после оформления заказа. На сайте опубликованы актуальные и трендовые модели на пошив, так же вы можете заказать пошив по вашим моделям.",
    additional:
      "Для получения более детальной информации свяжитесь с нашими менеджерами.",
    contact: "связаться",
  },
  {
    id: 2,
    tabTitle: "Доставка",
    title: "Доставка",
    content:
      "Доставка готовых изделий осуществляется на условиях. Мы помогаем клиентам заключить договор с транспортной компанией, чтобы им было проще ориентироваться на рынке транспортно -экспедиционных услуг Кыргызской Республики.",
    additional:
      "Цена за доставку 1 кг товара — от 45 до 250 руб, срок доставки от 3 до 12 дней.",
    contact: "",
  },
  {
    id: 3,
    tabTitle: "Оплата",
    title: "Оплата",
    content:
      "Мы работаем по 100% предоплате. 50% оплаты необходимо произвести сразу после согласования моделей, цен и сроков, остальные 50% - по готовности товара, до отгрузки в транспортную компанию.",
    additional:
      "Оплату вы можете произвести любым удобным для вас способом: на расчетные счета, карту или наличными.",
    contact: "",
  },
];

export default tabs;
