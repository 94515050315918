import { createSlice } from "@reduxjs/toolkit";
import { fetchProductsApi } from "./action";

const initialState = {
  products: [],
  status: "idle",
  error: null,
};

const ProductsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductsApi.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload;
      })
      .addCase(fetchProductsApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default ProductsSlice.reducer;
