import { apiRoot } from "../../api/api";

export const callRequets = async ({ fullname, phone }) => {
  try {
    const resPost = await apiRoot.post("/call-requests/", { fullname, phone });
    return resPost.data;
  } catch (error) {
    throw error;
  }
};
