import { apiRoot } from "../../api/api";

export const fetchProductsId = async (id) => {
  try {
    const res = await apiRoot.get(`/products/${id}/`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
