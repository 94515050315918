import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import Mesage from "../../assets/image/message.png";
import operatorSvg from "../../assets/image/operator.png";

import "./Footer.css";
import CheckModal from "../CheckModal/CheckModal";
import { useDispatch, useSelector } from "react-redux";
import { callRequetsAsync } from "../../redux/call-requests/action";

function Footer() {
  const [isActive, setIsActive] = useState(false);
  const [popup, setPopup] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const wrappRef = useRef(null);
  const [fullname, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const { data, error } = useSelector((state) => state.callRequests);

  useEffect(() => {
    if (data === "succeeded" && !error) {
      setModal(true);
    }
  }, [data, error]);

  const handleRequests = (e) => {
    e.preventDefault();
    if (fullname.trim() === "" || phone.trim() === "") {
      alert("Пожалуйста, заполните все поля.");
    } else {
      dispatch(callRequetsAsync({ fullname, phone })).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          setFullName("");
          setPhone("");
          setModal(true);
          setIsVisible(false);
        }
      });
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleClick = () => {
    setIsActive(true);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsActive(false);
    });
  };

  useEffect(() => {
    function handleOut(event) {
      if (wrappRef.current && !wrappRef.current.contains(event.target)) {
        setPopup(false);
      }
    }
    document.addEventListener("mousedown", handleOut);
    return () => {
      document.removeEventListener("mousedown", handleOut);
    };
  }, [wrappRef]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 20000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer-logo">
            <div className="logo">
              <Link to="/" style={{ color: "#37517e", marginBottom: "5px" }}>
                IMPERIAL STYLE
              </Link>
            </div>
            <div className="footer-address">
              <strong style={{ marginRight: "10px" }}>Адрес:</strong> г. Бишкек,
              ул. Коперника 61
            </div>
            <a href="tel:+996500992220" className="link">
              <strong>Phone:</strong>+996 500 992 220
            </a>
            <a href="molito: imperial.style3@gmail.com" className="link">
              <strong>Email:</strong>imperial.style3@gmail.com
            </a>
            <p>ОсОО: «IMPERIAL STYLE»</p>
          </div>
          <div className="footer-menu">
            <div className="footer-link">Полезные ссылки</div>
            <div className="menu-link">
              <Link onClick={handleClick} to="/">
                Что шьём
              </Link>
              <Link onClick={handleClick} to="/catalog">
                Каталог
              </Link>
              <Link onClick={handleClick} to="/about">
                О компании
              </Link>
              <Link onClick={handleClick} to="/why">
                Почему мы
              </Link>
              <Link onClick={handleClick} to="/contact">
                Контакты
              </Link>
            </div>
          </div>
          <div className="footer-network">
            <div className="our-network">Наши социальные сети</div>
            <div className="footer-icons">
              <a
                href="https://wa.me/+996500992220"
                target="blank"
                className="icon"
              >
                <svg
                  width="30"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                    fill="#182d9a"
                  />
                </svg>
              </a>
              <a href="https://telegram.org" target="blank" className="icon">
                <svg
                  width="34"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 496 512"
                >
                  <path
                    d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z"
                    fill="#182d9a"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
      <div className="network-fixed">
        <div className="container">
          <div ref={wrappRef} className="fixed">
            <div onClick={() => setPopup(!popup)} className="fixed-base">
              <img src={Mesage} alt="message svg" loading="lazy" />
            </div>
            <div className={`fixed-content ${popup ? "active" : ""}`}>
              <div className="fixed-icons">
                <a
                  href="https://wa.me/+996500992220"
                  target="blank"
                  className="icon"
                >
                  <svg
                    width="40"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                      fill="#182d9a"
                    />
                  </svg>
                </a>
                <a
                  href="https://t.me/+996500992220"
                  target="blank"
                  className="icon"
                >
                  <svg
                    width="44"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 496 512"
                  >
                    <path
                      d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z"
                      fill="#182d9a"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isActive}
      {isVisible && (
        <section className={`ad-banner ${isVisible ? "show" : "hide"}`}>
          <div className="container">
            <div className="ad-banner-first">
              <div className="ad-banner-image">
                <img src={operatorSvg} alt="operator" loading="lazy" />
              </div>
              <div>
                <form className="ad-banner-text" onSubmit={handleRequests}>
                  <p>
                    "Оставьте свой номер и получите бесплатный расчёт на ваши модели прямо сейчас!"
                  </p>
                  <input
                    type="text"
                    placeholder="Имя"
                    value={fullname}
                    onChange={(e) => setFullName(e.target.value)}
                    className="name"
                  />
                  <input
                    type="text"
                    placeholder="Номер телефона"
                    className="phone-number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <button className="submit" type="submit">
                    Оставить заявку
                  </button>
                </form>
                <div className="close-position">
                  <button className="close-banner" onClick={handleClose}>
                    Закрыть
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {modal && <CheckModal closeModal={closeModal} isActive={modal} />}
    </>
  );
}

export default Footer;
