import React from "react";

import commitments from "../../data/commitments";

import "./style.css";

const Commitments = () => {
  return (
    <section className="commitments">
      <div className="container">
        <h2 className="commitments__text__first">Наши обязательства</h2>
        <div className="commitments__box">
          {commitments.map((el) => (
            <div className="commitments__card" key={el.id}>
              <h2 className="commitments__text">{el.title}</h2>
              <p className="commitments__description">{el.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Commitments;
